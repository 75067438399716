import styled from 'styled-components';
import { colorPalette, device, getSpacing, typography } from 'stylesheet';

export const HomePageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media ${device.mobileMax} {
    margin-top: 10%;
  }
  @media ${device.mobileSmall_Max} {
    margin-top: 60vh;
  }
  @media ${device.mobileSmall_Max} and (min-height: 500px) {
    margin-top: 45vh;
  }
  @media ${device.mobileSmall_Min} and ${device.mobileMedium_Max} {
    margin-top: 25vh;
  }
  @media ${device.mobileMedium_Min} and ${device.mobileMax} {
    margin-top: 15vh;
  }
  @media ${device.mobileMedium_Max} and (min-height: 800px) {
    margin-top: 10vh;
  }
  @media ${device.mobileMedium_Min}and (min-height: 850px) {
    margin-top: 5vh;
  }
  @media ${device.laptop}and (min-height: 1300px) {
    margin-top: -10vh;
  }
  @media ${device.mobileMedium_Max}and (min-height: 750px) {
    margin-top: 10vh;
  }
  @media ${device.mobileMedium_Max}and (min-height: 745px) {
    margin-top: 15vh;
  }
  @media (width: 414px) and (height: 896px) {
    margin-top: 10vh;
  }
  @media (width: 375px) and (height: 667px) {
    margin-top: 30vh;
  }
  @media (width: 412px) and (height: 915px) {
    margin-top: 23vh;
  }
  @media (width: 414px) and (height: 736px) {
    margin-top: 23vh;
  }
  @media (width: 320px) and (height: 568px) {
    margin-top: 50vh;
  }
  @media (width: 320px) and (height: 480px) {
    margin-top: 65vh;
  }
`;

export const HomeImage = styled.img`
  width: 215px;
  height: 161px;
`;

export const Title = styled.h1`
  margin-top: ${getSpacing(4)};
  ${typography.title_small};
  margin-bottom: ${getSpacing(10)};
  @media ${device.mobileMax} {
    margin-bottom: ${getSpacing(2)};
    margin-top: ${getSpacing(45)};
  }
  > #highlightedName {
    text-transform: capitalize;
    color: ${colorPalette.secondary_600};
  }
`;

export const HomeTitleContainer = styled.div`
 
  }
`;
export const MenuSectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${getSpacing(5)};
  @media ${device.mobileMax} {
    display: flex;
    flex-direction: column;
    gap: ${getSpacing(0)};
  }
  @media ${device.mobileMin}and ${device.tabletMax} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: ${getSpacing(3)};
  }
  @media ${device.tabletMin} and ${device.laptopMax} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
