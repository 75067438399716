import styled from 'styled-components';
import { device, frameHeight, getSpacing } from 'stylesheet';

export const HeaderContainer = styled.header`
  display: flex;

  @media ${device.tabletMax} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fffff7;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  @media ${device.mobileMax} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${device.tabletMin} and ${device.laptopMax} {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  @media ${device.laptopMin} {
    justify-content: space-between;
    align-items: center;
    height: ${frameHeight.header};
    padding: ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(5)};
  }
`;
export const UserCardContainer = styled.div`
  @media ${device.laptopMax} {
    margin-right: 2%;
    margin-left: 2%;
    padding-top: 20px;
  }
  @media ${device.tabletMax} {
    padding-bottom: 30px;
  }
`;
export const SelectionContainer = styled.div`
  @media ${device.laptopMax} {
    width: 220px;
    padding-top: 20px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  @media ${device.tabletMin} and ${device.laptopMax} {
    margin-right: 3%;
    margin-left: 3%;
  }
`;

export const HeaderBrandImageContainer = styled.div`
  margin: 0 4px;
  height: ${frameHeight.header};
  padding: ${getSpacing(2)} 0;
`;

export const HeaderBrandImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const CreateHamBurgerButtonContainer = styled.div`
  @media ${device.tabletMin} {
    display: none;
  }
  @media ${device.tabletMax} {
    padding-left: 10px;
  }

  display: flex;
  justify-content: center;
  align-item: center;
  margin-bottom: ${getSpacing(2)};
  margin-top: 20px;
`;

export const sidePanelContainer = styled.div`
  width: 20%;
  margin-left: 2%;
  margin-right: 2%;
  border: 2px solid green;
`;
